import React, { useState, useEffect, useRef } from 'react';
import './App.css'; // Ensure you import your CSS file
import { Connection, PublicKey } from '@solana/web3.js'; // Import Solana libraries

const tokenAddress = new PublicKey('ca');

// Define rank tiers and their names
const rankTiers = [
  { name: 'Peasant', min: 0, max: 1000 },
  { name: 'Follower', min: 1001, max: 10000 },
  { name: 'Devotee', min: 10001, max: 100000 },
  { name: 'Chosen One', min: 100001, max: 1000000 }
];

// Divine messages encouraging more token purchases subtly
const divineMessages = [
  "The Gods whisper to you… ‘More favor, more blessings.’",
  "Purchase more tokens to earn the attention of the Gods.",
  "The Gods are watching your progress. Keep increasing your favor!",
  "A divine quest awaits: More tokens could bring greater rewards from the Gods.",
];

// Calculate current rank and progress to next rank
const calculateRankProgress = (score) => {
  for (let i = 0; i < rankTiers.length; i++) {
    const tier = rankTiers[i];
    if (score >= tier.min && score <= tier.max) {
      const progress = ((score - tier.min) / (tier.max - tier.min)) * 100; // Progress within current tier
      return { rank: tier.name, progress: progress.toFixed(2), nextTierMax: tier.max };
    }
  }
  return { rank: 'Ascended', progress: 100, nextTierMax: null }; // For scores > 1,000,000
};

const tutorialText = `
Type <span class="neon-purple">"connect"</span> to connect wallet<br>
Type <span class="neon-purple">"disconnect"</span> to disconnect wallet<br>
Type <span class="neon-purple">"community"</span> to view Community Links<br>
Type <span class="neon-purple">"ca"</span> to view contract address<br>
Type <span class="neon-purple">"score"</span> to view my score<br>
Type <span class="neon-purple">"quests"</span> to view my quests<br>
Type <span class="neon-purple">"reset"</span> to clear chat history<br>
Type <span class="neon-purple">"balance"</span> to view my balances
`;

function App() {
  const [provider, setProvider] = useState(null);
  const [publicKey, setPublicKey] = useState(null);
  const [inputValue, setInputValue] = useState('');
  const [output, setOutput] = useState(['']); // Initialize output state
  const [loading, setLoading] = useState(false);
  const [rankData, setRankData] = useState({ rank: 'Peasant', progress: 0, nextTierMax: 1000 }); // Store rank data
  const inputRef = useRef(null); // Reference to automatically focus input field
  const connection = new Connection('https://go.getblock.io/436244e138a840f49851611665fc6a25', 'confirmed'); // Using your RPC

  // Get current timestamp
  const getTimeStamp = () => {
    const now = new Date();
    return now.toLocaleTimeString();
  };

  // Format the prompt (e.g. [12:30:01]@(null):$ ~)
  const formatPrompt = () => {
    const timestamp = getTimeStamp();
    const walletLast4 = publicKey ? publicKey.slice(-4) : 'null';
    return `[${timestamp}]@(${walletLast4}):$ ~ `;
  };

  // Detect Phantom Wallet and set provider in state
  const getProvider = () => {
    if ('phantom' in window) {
      const phantomProvider = window.phantom?.solana;
      if (phantomProvider?.isPhantom) {
        setProvider(phantomProvider); // Store provider in state
        return phantomProvider;
      }
    }
    window.open('https://phantom.app/', '_blank');
  };

  // Connect to Phantom Wallet
  const connectWallet = async () => {
    if (provider && publicKey) {
      slowLogOutput(`Already connected: ${publicKey}`);
      return;
    }
    const detectedProvider = getProvider();
    if (!detectedProvider) {
      slowLogOutput('Phantom Wallet not detected.');
      return;
    }

    try {
      setLoading(true); // Start loading bar
      const resp = await detectedProvider.connect(); // Connect Phantom Wallet
      const connectedPublicKey = resp.publicKey.toString();
      if (!publicKey) {
        setPublicKey(connectedPublicKey); // Only set publicKey if not set before
        await slowLogOutput(`Connected: ${connectedPublicKey}`);
        await displayScoreMessage(connectedPublicKey); // Display message based on score
      }
    } catch (err) {
      slowLogOutput(`Error: ${err.message}`);
    } finally {
      setLoading(false); // Stop loading bar
    }
  };

  // Fetch the user's SOL balance
  const fetchSolBalance = async (publicKey) => {
    try {
      const balance = await connection.getBalance(new PublicKey(publicKey));
      const sol = balance / 1000000000; // Convert lamports to SOL
      return sol; // Return balance for use in score calculation
    } catch (error) {
      return 0;
    }
  };

  // Fetch token balance
  const fetchTokenBalance = async (publicKey) => {
    try {
      const tokenAccounts = await connection.getParsedTokenAccountsByOwner(
        new PublicKey(publicKey),
        { mint: tokenAddress }
      );
      const balance = tokenAccounts.value[0]?.account?.data?.parsed?.info?.tokenAmount?.uiAmount || 0;
      return balance; // Return balance for use in score calculation
    } catch (error) {
      return 0;
    }
  };

  // Calculate and return score
  const calculateScore = async (solBalance, tokenBalance) => {
    const score = solBalance * (0.00006969 * tokenBalance + 1);
    return score.toFixed(5); // Return up to 5 digits
  };

  // Display a message based on the score range after connecting
  const displayScoreMessage = async (publicKey) => {
    const solBalance = await fetchSolBalance(publicKey);
    const tokenBalance = await fetchTokenBalance(publicKey);
    const score = await calculateScore(solBalance, tokenBalance);

    const rankInfo = calculateRankProgress(score);
    setRankData(rankInfo); // Update rank data for the meter

    if (score <= 1000) {
      await slowLogOutput(
        "Hello dipshit, wtf is this? If you want the attention of the Solana Gods, you need to increase your score. Type score to view your information, and come back when you've become a real man. Or at least the bare minimum of a man."
      );
    } else if (score > 1000 && score <= 10000) {
      await slowLogOutput(
        "Okay you're looking much better but you're still weak in front of the Gods. You're close to getting their attention but you still need a little bit more. Raise your score a little bit more and the Gods will give you an audience. Type score to view your information, and come back when you're serious."
      );
    } else if (score > 10000) {
      await slowLogOutput(
        "Hello subject, I'm guessing the question on your mind is WAGMI?\nYou've completed the basics, you've got a decent start. \nCome back shortly, we'll have something big ready for you."
      );
    }
  };

  // Fetch both SOL and BBK balances for the balance command
  const fetchBalances = async (publicKey) => {
    const solBalance = await fetchSolBalance(publicKey);
    await slowLogOutput(`Your SOL Balance: <span style="color: #8FBC8F;">${solBalance} SOL</span>`);
    const tokenBalance = await fetchTokenBalance(publicKey);
    await slowLogOutput(`Your token balance: <span style="color: #8FBC8F;">${tokenBalance} SHEKELZ</span>`);
  };

  // Disconnect from Phantom Wallet
  const disconnectWallet = async () => {
    if (!provider) {
      slowLogOutput("No wallet is connected.");
      return;
    }

    try {
      await provider.disconnect();
      setPublicKey(null);
      slowLogOutput(`Disconnected from wallet.`);
    } catch (err) {
      slowLogOutput(`Error: ${err.message}`);
    }
  };

  // Output text slowly, one letter at a time, and ensure outputs don't overlap
  const slowLogOutput = async (text) => {
    let index = 0;
    let currentLine = ''; // Store the current line to be appended
    return new Promise((resolve) => {
      const interval = setInterval(() => {
        if (index < text.length) {
          currentLine += text[index]; // Append one letter at a time
          setOutput((prevOutput) => {
            const newOutput = [...prevOutput.slice(0, -1), currentLine]; // Replace the last line
            return newOutput;
          });
          index++;
        } else {
          clearInterval(interval);
          setOutput((prevOutput) => [...prevOutput, '']); // Add a new empty line after the text completes
          resolve(); // Resolve the promise when the output completes
        }
      }, 50); // Adjust speed as necessary
    });
  };

  // Fetch and display score when the user types 'score'
  const fetchScore = async (publicKey) => {
    const solBalance = await fetchSolBalance(publicKey);
    const tokenBalance = await fetchTokenBalance(publicKey);
    const score = await calculateScore(solBalance, tokenBalance);
    const rankInfo = calculateRankProgress(score);
    setRankData(rankInfo); // Update rank data for the meter
    await slowLogOutput(
      `Your score with the Gods is <span style="color: #8FBC8F;">${score}</span> xp\n` +
      `You are a <span style="color: #8FBC8F;">${rankInfo.rank}</span> with ${rankInfo.progress}% progress to the next level (${rankInfo.nextTierMax} points).\n` +
      `Obtain more <span style="color: #8FBC8F;">SOL</span> or <span style="color: #8FBC8F;">SHEKELZ</span> to increase your favor with the Gods.`
    );
  };

  // Show a divine message randomly from the list
  const showDivineMessage = () => {
    const randomMessage = divineMessages[Math.floor(Math.random() * divineMessages.length)];
    slowLogOutput(`<span class="divine-message">${randomMessage}</span>`);
  };

  // Command prompt-like interaction
  const handleInput = (event) => {
    if (event.key === 'Enter') {
      processCommand(inputValue);
      setInputValue('');
    }
  };

  const processCommand = async (command) => {
    switch (command.toLowerCase()) {
      case 'connect':
        await connectWallet();
        break;
      case 'disconnect':
        await disconnectWallet();
        break;
      case 'ca':
        await slowLogOutput("PUMPFUN :");
        break;
      case 'score':
        await fetchScore(publicKey); // Fetch and display score
        break;
      case 'balance':
        await fetchBalances(publicKey); // Fetch both balances and wait for each
        break;
      case 'community':
        await slowLogOutput("TG: thegodsshekelz | X: shekelz_");
        break;
      case 'quests':
        await slowLogOutput("Coming soon");
        break;
      case 'reset':
        setOutput(['']); // Clear chat history
        break;
      default:
        await slowLogOutput(`Unknown command: ${command}`);
    }
  };

  // Automatically focus the input field when the window is active
  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }

    const focusInput = () => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    };

    // Listen for clicks and keypresses to re-focus the input field
    window.addEventListener('click', focusInput);
    window.addEventListener('keypress', focusInput);

    return () => {
      window.removeEventListener('click', focusInput);
      window.removeEventListener('keypress', focusInput);
    };
  }, []);

  return (
    <div className="App">
      {/* Navbar */}
      <div className="navbar">The Gods</div> {/* Updated to 'The Gods' */}

      {/* Favor Meter */}
      <div className="favor-meter">
        <div className="favor-fill" style={{ width: `${rankData.progress}%` }}></div>
        <p className="favor-text">Rank: {rankData.rank}, Progress: {rankData.progress}%</p>
      </div>

      {/* Command Box */}
      <div className="command-box">
        <div className="prompt-container">
          {/* Output Area */}
          <div className="prompt-output">
            <div style={{ color: 'teal' }} dangerouslySetInnerHTML={{ __html: tutorialText }}></div> {/* Teal Tutorial Text */}
            {output.map((line, index) => (
              <p key={index} className="glow-text" dangerouslySetInnerHTML={{ __html: line }} /> /* Yellow Output */
            ))}
          </div>

          {/* Loading bar */}
          {loading && <div className="loading-bar" style={{ width: loading ? '100%' : '0' }} />}

          {/* Input Area */}
          <div className="prompt-input">
            <span style={{ color: '#0f0' }}>{formatPrompt()}</span> {/* Static Input Line Text in Green */}
            <input
              type="text"
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              onKeyPress={handleInput}
              placeholder="Type a command..."
              ref={inputRef} // Automatically focus input
              style={{ color: '#fff' }} // Input text color to white
            />
          </div>
        </div>
      </div>

      {/* Footer Bar */}
      <div className="footer-bar">
        <div className="footer-left">CA: ca</div>
        <div className="footer-right">
          <a href="https://x.com/SHEKELZ_" target="_blank" rel="noopener noreferrer">Twitter</a>
          <a href="https://t.me/THEGODSSHEKELZ" target="_blank" rel="noopener noreferrer">Telegram</a>
        </div>
      </div>
    </div>
  );
}

export default App;
